.tooltip {
    position: relative;
    display: inline-block;
    margin-left: 5px;

    svg {
        height: 18px;
        width: 18px;
    }
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    //bottom: 125%;
    // left: 50%;
    margin-left: -150px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
    line-height: 1.3;
    font-weight: 400;
    padding-top: 5px;
    padding-bottom: 5px;
}

.tooltip .tooltiptext::after {
    //content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip-right {
    top: -5px;
    left: 175px;
}