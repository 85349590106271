.filter {
    &-wrapper {
        margin: 5px;
        position: relative;
    }

    &__button-wrapper {
        border-top: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;

        .button {
            margin: 0;
        }
    }

    &-item {
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 15px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;


        &.active {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }


    }

    &-checkboxes {
        padding: 15px;
        max-height: 200px;
        overflow-y: scroll;
    }

    &-values {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        position: absolute;
        left: 0;
        right: 0;
        background: white;
        z-index: 100;

        label {
            display: flex;
            line-height: 2;
            align-items: center;
            cursor: pointer;
        }

        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: currentColor;
            border: 1px solid #ccc;
            width: 20px;
            height: 20px;
            padding: 0;
            border-radius: 100%;
            margin-right: 5px;
            display: grid;
            place-content: center;
        }

        input[type="checkbox"]::before {
            content: "";
            width: 20px;
            height: 20px;
            color: white;
        }

        input[type="checkbox"]:checked {

            background-color: rgba(255, 0, 0, 1);
            border: none;
        }
    }


}

.button {
    background-color: #e30513;
    border-color: #e30513;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;
    padding: 0.375rem 0.75rem !important;
    text-align: center;
    border-style: none;
}