.popup {
  &:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 200ms;
    opacity: 0.5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &--content-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &--content {
    width: 600px;
    min-height: 400px;
    padding: 50px;
    border-radius: 5px;
    background: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
}

.error {
  padding: 10px;
  border: 1px solid #96233A;
  color: #96233A;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}

.success {
  padding: 10px;
  border: 2px solid #13AA66;
  color: #13AA66;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}

.checkbox {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

  input {
    margin-top: 7px;
    margin-right: 5px;
  }
}

.trainer-block {
  background: lightblue;
  margin-left: -20px;
  padding-left: 20px;
  margin-right: -20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 20px;
  border-radius: 5px;
}